<template>
  <div>
    <v-card>
      <v-toolbar class="indigo darken-4 white--text" dark>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>

        <v-toolbar-title>Grafica</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon x-large v-on="on" @click.stop="dialogBoat = true">
              <v-icon>directions_boat</v-icon>
            </v-btn>
          </template>
        </v-tooltip>

        <!-- DIALOG NEW USER -->
      </v-toolbar>
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <h3>INDUSTRIAL</h3>
                <!--// Pescadores asociados vs pescadores independientes-->
                <highcharts :options="options1" ref="highcharts1"></highcharts>
              </v-col>
              <v-col cols="12">
                <!--// Pescadores por tipo de pesquería-->
                <highcharts :options="options2" ref="highcharts2"></highcharts>
              </v-col>
              <v-col cols="12">
                <!--// Pescadores por tipo de pesquería -->
                <highcharts :options="options3" ref="highcharts3"></highcharts>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <h3>ARTESANAL</h3>
                <!--// Pescadores asociados vs pescadores independientes-->
                <highcharts
                  :options="options11"
                  ref="highcharts11"
                ></highcharts>
              </v-col>
              <v-col cols="12">
                <!--// Pescadores por tipo de pesquería-->
                <highcharts
                  :options="options22"
                  ref="highcharts22"
                ></highcharts>
              </v-col>
              <v-col cols="12">
                <!--// Pescadores por tipo de pesquería -->
                <highcharts
                  :options="options33"
                  ref="highcharts33"
                ></highcharts>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import {
  getBoatZoneAutorizedUrl,
  getBoatPortLandingUrl,
  getBoatPortLandingArtUrl,
  getBoatArtFisherUrl,
  getBoatArtFisherArtUrl,
  getBoatfisherAutorizedUrl,
  getBoatfisherAutorizedArtUrl,
  getHeader,
} from "./../../config.js";
import Highcharts3D from "highcharts/highcharts-3d";
import Highcharts from "highcharts";
Highcharts3D(Highcharts);

var options1 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "No. de barcos / Puerto de desembarco autorizado",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Total",
      colorByPoint: true,
      data: [],
    },
  ],
};
var options11 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "No. de barcos / Puerto de desembarco autorizado",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Total",
      colorByPoint: true,
      data: [],
    },
  ],
};
var options2 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "No. de barcos / Arte de pesca",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Total",
      colorByPoint: true,
      data: [],
    },
  ],
};
var options22 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "No. de barcos / Arte de pesca",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Total",
      colorByPoint: true,
      data: [],
    },
  ],
};
var options3 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "No. de barcos / Pesquería autorizada - Especies Objetivo",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Total",
      colorByPoint: true,
      data: [],
    },
  ],
};
var options33 = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "No. de barcos / Pesquería autorizada - Especies Objetivo",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [
    {
      name: "Total",
      colorByPoint: true,
      data: [],
    },
  ],
};

export default {
  data: () => ({
    options1: options1,
    options11: options11,
    options2: options2,
    options22: options22,
    options3: options3,
    options33: options33,
    totalPescadores: "",
    numBarcosAutorized: "",
  }),
  methods: {
    // Total de pescadores Providencia y Santa Catalina
    getBoatZoneAutorized() {
      this.axios
        .get(getBoatZoneAutorizedUrl, { headers: getHeader() })
        .then((response) => {
          this.totalPescadores = response.data.fishes;
          let arrayGraphic = "";
          this.totalPescadores.forEach((element) => {
            const dataArray = {
              name: ":" + element.organization_cooperative_count,
              y: element.organization_cooperative_count,
              sliced: true,
            };

            arrayGraphic = options1.series[0].data;
            arrayGraphic.push(dataArray);
          });
        });
    },
    // No. de barcos / Puerto de desembarco autorizado en Colombia
    getBoatPortLanding() {
      this.axios
        .get(getBoatPortLandingUrl, { headers: getHeader() })
        .then((response) => {
          this.numBarcosAutorized = response.data;
          //limpiar data
          options1.series[0].data = [];
          Object.keys(this.numBarcosAutorized).forEach((element) => {
            var total = this.numBarcosAutorized[element];
            const dataArray = {
              name: element + " : " + total,
              y: total,
              sliced: true,
            };
            const arrayGraphic = options1.series[0].data;
            arrayGraphic.push(dataArray);
          });
        });
    },
    // No. de barcos / Puerto de desembarco autorizado en Colombia
    getBoatPortLandingArt() {
      this.axios
        .get(getBoatPortLandingArtUrl, { headers: getHeader() })
        .then((response) => {
          this.numBarcosAutorized = response.data;
          //limpiar data
          options11.series[0].data = [];
          Object.keys(this.numBarcosAutorized).forEach((element) => {
            var total = this.numBarcosAutorized[element];
            const dataArray = {
              name: element + " : " + total,
              y: total,
              sliced: true,
            };
            const arrayGraphic = options11.series[0].data;
            arrayGraphic.push(dataArray);
          });
        });
    },
    // No. de barcos / Arte de pesca
    getBoatArtFisher() {
      this.axios
        .get(getBoatArtFisherUrl, { headers: getHeader() })
        .then((response) => {
          this.totalPescadores = response.data;
          //limpiar data
          options2.series[0].data = [];
          Object.keys(this.totalPescadores).forEach((element) => {
            var total = this.totalPescadores[element];
            const dataArray = {
              name: element + " : " + total,
              y: total,
              sliced: true,
            };
            const arrayGraphic = options2.series[0].data;
            arrayGraphic.push(dataArray);
          });
        });
    },
    // No. de barcos / Arte de pesca artesanal
    getBoatArtFisherArt() {
      this.axios
        .get(getBoatArtFisherArtUrl, { headers: getHeader() })
        .then((response) => {
          this.totalPescadores = response.data;
          //limpiar data
          options22.series[0].data = [];
          Object.keys(this.totalPescadores).forEach((element) => {
            var total = this.totalPescadores[element];
            const dataArray = {
              name: element + " : " + total,
              y: total,
              sliced: true,
            };
            const arrayGraphic = options22.series[0].data;
            arrayGraphic.push(dataArray);
          });
        });
    },
    // No. de barcos / Pesquería autorizada - Especies Objetivo
    getBoatfisherAutorized() {
      this.axios
        .get(getBoatfisherAutorizedUrl, { headers: getHeader() })
        .then((response) => {
          this.totalPescadores = response.data;

          //limpiar data
          options3.series[0].data = [];

          Object.keys(this.totalPescadores).forEach((element) => {
            var total = this.totalPescadores[element];
            const dataArray = {
              name: element + " : " + total,
              y: total,
              sliced: true,
            };
            const arrayGraphic = options3.series[0].data;
            arrayGraphic.push(dataArray);
          });
        });
    },
    // No. de barcos / Pesquería autorizada - Especies Objetivo
    getBoatfisherAutorizedArt() {
      this.axios
        .get(getBoatfisherAutorizedArtUrl, { headers: getHeader() })
        .then((response) => {
          this.totalPescadores = response.data;

          //limpiar data
          options33.series[0].data = [];

          Object.keys(this.totalPescadores).forEach((element) => {
            var total = this.totalPescadores[element];
            const dataArray = {
              name: element + " : " + total,
              y: total,
              sliced: true,
            };
            const arrayGraphic = options33.series[0].data;
            arrayGraphic.push(dataArray);
          });
        });
    },
  },
  created() {
    //this.getBoatZoneAutorizedUrl();
    this.getBoatPortLanding();
    this.getBoatPortLandingArt();
    this.getBoatArtFisher();
    this.getBoatArtFisherArt();
    this.getBoatfisherAutorized();
    this.getBoatfisherAutorizedArt();
  },
};
</script>
